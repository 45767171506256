import Seed from './Seed';

class Population {

    valueClass = Set;

    constructor() {
        this.map = new Map();
    }

    has(x, y) {

        const X = this.map.get(x);

        if (X instanceof Set) {
            return X.has(y);
        }
        return false;
    }

    at(x, y) {
        return this.has(x, y);
    }

    isKill(n) {
        return n < 2 || n > 3;
    }

    isResurrect(n) {
        return n === 3;
    }

    set(x, y, alive) {

        let X = this.map.get(x);

        if (alive) {
            if (!X) {
                X = new Set();
                this.map.set(x, X);
            }
            X.add(y);

        } else {
            if (X) {
                X.delete(y);

                if (X.size === 0) {
                    this.map.delete(x);
                }
            }
        }
    }

    getLiveNeighbors = ({ px, nx, py, ny }, x, y) => {

        let liveNeighbors = 0;
        let X = this.map.get(x);

        if (X instanceof this.valueClass) {
            if (X.has(py)) {
                liveNeighbors++;
            }

            if (X.has(ny)) {
                liveNeighbors++;
            }
        }

        X = this.map.get(px);

        if (X instanceof this.valueClass) {
            if (X.has(py)) {
                liveNeighbors++;
            }
            if (X.has(y)) {
                liveNeighbors++;
            }
            if (X.has(ny)) {
                liveNeighbors++;
            }
        }

        X = this.map.get(nx);

        if (X instanceof this.valueClass) {
            if (X.has(py)) {
                liveNeighbors++;
            }
            if (X.has(y)) {
                liveNeighbors++;
            }
            if (X.has(ny)) {
                liveNeighbors++;
            }
        }

        return liveNeighbors;
    }

    transpose(map) {

        const transposed = new Map();

        for (let [ x, X ] of map) {
            for (let y of X) {

                let newX = transposed.get(y);

                if (!newX) {
                    newX = new Set();
                    transposed.set(y, newX);
                }
                newX.add(x);
            }
        }

        return transposed;
    }
}

class GradientPopulation extends Population {

    valueClass = Map;

    has(x, y) {

        const X = this.map.get(x);

        if (X instanceof Map) {
            return X.has(y);
        }
        return false;
    }

    at(x, y) {

        const X = this.map.get(x);

        if (X instanceof Map) {
            return X.get(y);
        }
    }

    isKill(n) {
        return (n < 2 || n > 3) && n !== 8;
    }

    set(x, y, value) {

        let X = this.map.get(x);

        if (value !== undefined) {
            if (!X) {
                X = new Map();
                this.map.set(x, X);
            }
            X.set(y, value);

        } else {
            if (X) {
                X.delete(y);

                if (X.size === 0) {
                    this.map.delete(x);
                }
            }
        }
    }

    transpose(map) {

        const transposed = new Map();

        for (let [ x, X ] of map) {
            for (let [ y, v ] of X) {

                let newX = transposed.get(y);

                if (!newX) {
                    newX = new Map();
                    transposed.set(y, newX);
                }
                newX.set(x, v);
            }
        }

        return transposed;
    }
}

class Potentials {

    constructor() {
        this.map = new Map();
    }

    increment({ px, nx, py, ny }, x, y) {

        let X = this.map.get(x);

        if (!(X instanceof Map)) {
            X = new Map();
            this.map.set(x, X);
        }

        let PX = this.map.get(px);

        if (!(PX instanceof Map)) {
            PX = new Map();
            this.map.set(px, PX);
        }

        let NX = this.map.get(nx);

        if (!(NX instanceof Map)) {
            NX = new Map();
            this.map.set(nx, NX);
        }

        X.set(py, (X.get(py) || 0) + 1);
        X.set(y, (X.get(y) || 0) + 1);
        X.set(ny, (X.get(ny) || 0) + 1);

        PX.set(py, (PX.get(py) || 0) + 1);
        PX.set(y, (PX.get(y) || 0) + 1);
        PX.set(ny, (PX.get(ny) || 0) + 1);

        NX.set(py, (NX.get(py) || 0) + 1);
        NX.set(y, (NX.get(y) || 0) + 1);
        NX.set(ny, (NX.get(ny) || 0) + 1);
    }

    decrement({ px, nx, py, ny }, x, y) {

        let v;
        const X = this.map.get(x);

        v = (X.get(py) || 0) - 1;
        if (v > 0) {
            X.set(py, v);
        } else {
            X.delete(py);
        }

        v = (X.get(y) || 0) - 1;
        if (v > 0) {
            X.set(y, v);
        } else {
            X.delete(y);
        }

        v = (X.get(ny) || 0) - 1;
        if (v > 0) {
            X.set(ny, v);
        } else {
            X.delete(ny);
        }

        const PX = this.map.get(px);

        v = (PX.get(py) || 0) - 1;
        if (v > 0) {
            PX.set(py, v);
        } else {
            PX.delete(py);
        }

        v = (PX.get(y) || 0) - 1;
        if (v > 0) {
            PX.set(y, v);
        } else {
            PX.delete(y);
        }

        v = (PX.get(ny) || 0) - 1;
        if (v > 0) {
            PX.set(ny, v);
        } else {
            PX.delete(ny);
        }

        const NX = this.map.get(nx);

        v = (NX.get(py) || 0) - 1;
        if (v > 0) {
            NX.set(py, v);
        } else {
            NX.delete(py);
        }

        v = (NX.get(y) || 0) - 1;
        if (v > 0) {
            NX.set(y, v);
        } else {
            NX.delete(y);
        }

        v = (NX.get(ny) || 0) - 1;
        if (v > 0) {
            NX.set(ny, v);
        } else {
            NX.delete(ny);
        }
    }

    update(adjacent, x, y, v) {

        if (v) {
            this.increment(adjacent, x, y);
        } else {
            this.decrement(adjacent, x, y);
        }
    }

    transpose(map) {

        const transposed = new Map();

        for (let [ x, X ] of map) {
            for (let [ y, n ] of X) {

                let newX = transposed.get(y);

                if (!newX) {
                    newX = new Map();
                    transposed.set(y, newX);
                }
                newX.set(x, n);
            }
        }

        return transposed;
    }
}

export default class Data {

    max = {};

    constructor({ x, y }, sources) {

        this.max = { x, y };

        const isBinary = !sources.find(
            ({ x, y, seed }) => seed.type === Seed.CUSTOM_STRING
        );

        if (isBinary) {
            this.population = new Population();
        } else {
            this.population = new GradientPopulation();
        }

        this.potentials = new Potentials();
        this.populate(sources);
    }

    populate(seeds) {

        for (let { x: offsetX, y: offsetY, seed } of seeds) {

            if (offsetX === undefined) {
                offsetX = Math.floor((this.max.x - seed.width) / 2);
            }

            if (offsetY === undefined) {
                offsetY = Math.floor((this.max.y - seed.height) / 2);
            }

            for (let [ x, y, v ] of seed) {
                if (v > 0) {
                    this.update(
                        (x + offsetX) % this.max.x,
                        (y + offsetY) % this.max.y,
                        v
                    );
                }
            }
        }
    }

    getSurroundingXY(x, y) {

        const px = x === 0 ? this.max.x - 1 : x - 1;
        const nx = x === this.max.x - 1 ? 0 : x + 1;
        const py = y === 0 ? this.max.y - 1 : y - 1;
        const ny = y === this.max.y - 1 ? 0 : y + 1;

        return { px, nx, py, ny };
    }

    at(x, y) {
        return this.population.at(x, y);
    }

    update(x, y, v) {

        const surr = this.getSurroundingXY(x, y);

        this.population.set(x, y, v);
        this.setPotentials(surr, x, y, v);
    }

    getLiveNeighbors = (surrounding, x, y) => {
        return this.population.getLiveNeighbors(surrounding, x, y);
    }

    increment(surrounding, x, y) {
        return this.potentials.increment(surrounding, x, y);
    }

    decrement(surrounding, x, y) {
        return this.potentials.decrement(surrounding, x, y);
    }

    setPotentials(adjacent, x, y, v) {
        this.potentials.update(adjacent, x, y, v);
    }

    transposePopulation(map) {
        return this.population.transpose(map);
    }

    transposePotentials(map) {
        return this.potentials.transpose(map);
    }

    next(cb) {

        const q = this.getNext(cb);

        for (let [ x, y, v ] of q) {
            this.update(x, y, v);
        }
    }

    getNext(cb) {

        let q = [];

        for (let [ x, X ] of this.potentials.map) {
            for (let [ y, Y ] of X) {

                const surr = this.getSurroundingXY(x, y);
                const liveNeighbors = this.getLiveNeighbors(surr, x, y);

                if (this.population.has(x, y)) {
                    if (this.population.isKill(liveNeighbors)) {
                        q = [ ...q, [ x, y, false ]];
                        cb(x, y, false);
                    }
                } else {
                    if (this.population.isResurrect(liveNeighbors)) {
                        q = [ ...q, [ x, y, true ]];
                        cb(x, y, true);
                    }
                }
            }
        }

        return q;
    }

    rotate(rotateCells = true) {

        const tmp = this.max.x;

        this.max.x = this.max.y;
        this.max.y = tmp;

        if (rotateCells) {
            this.transpose();
        }
    }

    transpose() {
        this.population.map = this.transposePopulation(this.population.map);
        this.potentials.map = this.transposePotentials(this.potentials.map);
    }

    minmax() {

        const min = { x: Infinity, y: Infinity };
        const max = { x: 0, y: 0 };

        const grad = this.map instanceof GradientPopulation;

        for (let [ x, X ] of this.population.map) {
            if (x < min.x) {
                min.x = x;
            }
            if (x > max.x) {
                max.x = x;
            }
            for (let [ y, Y ] of X) {
                if (y < min.y) {
                    min.y = y;
                }
                if (y > max.y) {
                    max.y = y;
                }
            }
        }

        return { min, max };
    }
    
    center() {

        const { min, max } = this.minmax();

        const xlen = max.x - min.x;
        const ylen = max.y - min.y;

        const mxcent = min.x + Math.floor(xlen / 2);
        const vxcent = Math.floor(this.max.x / 2); 
        const offx = vxcent - mxcent;

        const mycent = min.y + Math.floor(ylen / 2);
        const vycent = Math.floor(this.max.y / 2); 
        const offy = vycent - mycent;

        this.translate(offx, offy);
    }

    translateX(offset) {

        let subject = Array.from(this.population.map);

        const asc = subject[ 0 ][ 0 ] < subject[ this.population.map.size - 1 ][ 0 ];

        if (offset < 0) {
            if (!asc) {
                subject = subject.reverse();
            }
        } else if (offset > 0) {
            if (asc) {
                subject = subject.reverse();
            }
        } else {
            return;
        }

        for (let [ x, X ] of subject) {

            const newX = x + offset;

            this.population.map.set(newX, X);
            this.population.map.delete(x);
        }
    }

    translateY(offset) {

        let first = Array.from(Array.from(this.population.map)[ 0 ][ 1 ]);
        let reverse = false;

        const asc = first[ 0 ][ 0 ] < first[ first.length - 1 ][ 0 ];

        if (offset < 0) {
            if (!asc) {
                reverse = true;
            }
        } else if (offset > 0) {
            if (asc) {
                reverse = true;
            }
        } else {
            return;
        }

        for (let [ x, X ] of this.population.map) {

            let subject = reverse ? Array.from(X).reverse() : Array.from(X);

            for (let [ y, Y ] of subject) {

                const newY = y + offset;

                X.set(newY, Y);
                X.delete(y);
            }
        }
    }

    translate(offx, offy) {

        this.translateX(offx);
        this.translateY(offy);
    }
}
