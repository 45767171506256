import UI from './lib';
import { Seed } from './lib';
import lf from './lifeForms';
import './css/style.css';

const conf = {
    cellSize: 5,
    borderWidth: 1,
    interval: 50,
    themes: {
        light: {
            fill: {
                alive: '#ddd',
                dead: '#fefefe',
            },
            outline: '#f5f5f5',
        },
        dark: {
            fill: {
                alive: '#373737',
                dead: '#222',
            },
            outline: '#1d1d1d',
        },
    },
};

const smallPool = [
    lf.total_aperiodic,
    lf.snark_loop,
    lf.queen_bee_shuttle,
];

const largePool = [
    //lf.total_aperiodic,
    //lf.infinite_glider_hotel_small,
    //lf.switch_engine_channel,
    //lf.two_engine_cordership,
    //lf.sixteen,
    lf.first_cordership,
]

document.addEventListener('DOMContentLoaded', () => {

    const gameEl = document.getElementById('game');
    const body = document.body;

    const ui = new UI(
        document.body,
        document.getElementById('game'),
        conf
    );

    let pool = largePool;

    if (document.body.clientWidth < 400 || document.body.clientHeight < 400) {
        pool = smallPool;
    }

    const i = Math.floor(Math.random() * pool.length);

    const sources = [
        //{ x: 0, y: 0, seed: new Seed(lf.glider) },
        {
            ...ui.randomCoordinate(),
            seed: new Seed(pool[ i ])
        },
    ];

	ui.seed(sources);

    if (0) {
        const playBtn = document.querySelector('#player button:first-child');
        playBtn.addEventListener('click', () => {
            playBtn.classList.toggle('play');
            playBtn.classList.toggle('pause');
            ui.togglePlay();
        });

        const stepBtn = document.querySelector('#player button:nth-child(2)');
        stepBtn.addEventListener('click', () => {
            ui.step();
        });
    }

    const btn = document.querySelector('.toggle-theme');

    btn.addEventListener('click', () => {
        document.body.classList.toggle('dark-theme');
        document.body.classList.toggle('light-theme');
        ui.setTheme(ui.theme === UI.DARK_THEME ? UI.LIGHT_THEME : UI.DARK_THEME);
    });

    if (!window.matchMedia) {
        console.log('no match media');
    } else {
        console.log('yes match media');
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        ui.setTheme(UI.DARK_THEME);
    } else {
        ui.setTheme(UI.LIGHT_THEME);
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        ui.setTheme(event.matches ? UI.DARK_THEME : UI.LIGHT_THEME);
    });

    screen.orientation.addEventListener('change', event => ui.rotate());

    ui.start();

    //setTimeout(() => ui.start(), 2000);

    document.addEventListener('mousemove', e => {
        //console.log(`Screen X/Y: ${e.screenX}, ${e.screenY}`);
        //console.log(`Client X/Y: ${e.clientX}, ${e.clientY}`);
        const x = e.clientX;
        const y = e.clientY;
    });
});
