export default class Seed {

    #source;
    #type;
    #offset = {
        x: 0,
        y: 0,
    };
    #cache = [];
    #gens = {};
    #width = 0;
    #height = 0;

    static ARRAY = 'ARR';
    static RLE_STRING = 'RLE';
    static CUSTOM_STRING = 'CUS';

    constructor(source) {

        this.#source = source;
        this.#type = this.#getType(source);

        this.#gens = {
            [ Seed.ARRAY ]: this.binArr.bind(this),
            [ Seed.RLE_STRING ]: this.runLengthEncoded.bind(this),
            [ Seed.CUSTOM_STRING ]: this.customString.bind(this),
        };
    }

    get height() {
        if (!this.#cache.length) {
            let q = [];

            for (let row of this.#gens[ this.#type ](this.#source)) {
                q = [ ...q, row ];
            }
            this.#cache = q;
        }
        return this.#height;
    }

    get width() {
        if (!this.#cache.length) {
            let q = [];

            for (let row of this.#gens[ this.#type ](this.#source)) {
                q = [ ...q, row ];
            }
            this.#cache = q;
        }
        return this.#width;
    }

    get type() {
        return this.#type;
    }

    #getType(source) {

        if (Array.isArray(source)) {
            return Seed.ARRAY;

        } else if (typeof source === 'string') {
            if (/^[\dbo$!]+$/.test(source)) {
                return Seed.RLE_STRING;
            } else if (source[ source.length - 1 ] === '#') {
                return Seed.CUSTOM_STRING;
            }
        }
        throw new Error('Source of unknown origin');
    }

    *[ Symbol.iterator ]() {
        
        if (this.#cache.length) {
            for (let row of this.#cache) {
                yield row;
            }
        } else {
            let q = [];

            for (let row of this.#gens[ this.#type ](this.#source)) {
                q = [ ...q, row ];
                yield row;
            }
            this.#cache = q;
        }
    }

    *runLengthEncoded(str) {
    
        let trunc = str.slice(0, str.indexOf('!')),
            d = '',
            i = 0,
            j = 0,
            q = [],
            w = 0,
            h = 1;

        for (let c of [ ...trunc ]) {

            if (/\d/.test(c)) {
                d += c;
                continue;
            }

            if ([ 'b', 'o' ].includes(c)) {

                let n = d ? parseInt(d) : 1;
                w += n;

                while (n--) {
                    if (c === 'o') {
                        yield [ i, j, true ];
                    }
                    j++;
                }
            } else if (c === '$') {
                i++;
                h++;
                j = 0;
                if (w > this.#width) {
                    this.#width = w;
                }
                w = 0;
            } else if (c === '!') {
                break;
            } else {
                throw new Error(`Unrecognized character '${ c }'`);
            }
            d = '';
        }
        this.#height = h;
    }

    *customString(str) {

        const trunc = str.slice(0, str.indexOf('#'));
        const rows = trunc.split('$');
        this.#width = rows.length;

        let i = 0;

        for (let row of rows) {

            let j = 0, w = 0;

            for (let cellStr of row.split(';')) {

                let hexStr, n = 1;

                if (cellStr.indexOf('.') > 0) {
                    let [ d, h ] = cellStr.split('.');
                    n = parseInt(d);
                    hexStr = h;
                } else {
                    hexStr = cellStr;
                }
                w += n;

                while (n--) {
                    let v = parseInt(hexStr, 16);
                    v = v === 0 ? 1 : v;
                    v = v === 255 ? 0 : v;
                    yield [ i, j, v ];
                    j++;
                }
            }
            if (w > this.#height) {
                this.#height = w;
            }
            i++;
        }
    }

    *binArr(arr) {

        if (arr.length) {
            
            const xLen = arr.length;
            const yLen = arr[ 0 ].length;

            for (let i = 0; i < xLen; i++) {
                for (let j = 0; j < yLen; j++) {
                    if (arr[ i ][ j ]) {
                        yield [ i, j, true ];
                    }
                }
            }
        }
    }
}
